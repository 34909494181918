/* contents */

.contents .content{height: 598px;max-width: 1500px;min-width: 1250px;margin: 0 auto;}
.contents .content.solution .container{display: block;}
/* left_txt */
.contents .content .container .left_txt{position: relative;padding-top: 110px;box-sizing: border-box;}
.contents .content .container .txt_title{position: absolute;bottom: 17px;right: 0;width: 625px;font-size: 100px;font-weight: bold;color: #f3f3f3;text-align: right;letter-spacing: -4px;padding-right: 20px;}
.contents .content .container .txt_title .left_align{text-align: left;margin-bottom: -17px;}
.contents .content .container .left_txt p.content_title{font-size: 31px;position: relative;padding: 0 30px;color: #303030;line-height: 48px;}
.contents .content .container .left_txt p.content_title::before{content: '';display: block;width: 5px;height: 47px;position: absolute;left: 0;background-color: #000;opacity: .15;top: 8px;}
.contents .content .container .left_txt>p em{font-weight: bold;color: #ef7f00;font-size: 44px;}
.contents .content .container .left_txt>p span{display: block;}

.contents .since .container .txt_title{color: #fff;}
.contents .since .container .left_txt p::before{background-color: #f88e16;opacity: 1;}

.contents .programming .container .left_txt .content_img{}
.contents .programming .container .left_txt .content_img img{display: block;margin: 0 auto;}

.contents .publishing .container .left_txt .content_img {margin: -50px 0 0 350px;}
.contents .publishing .container .left_txt .content_img ul{width: 784px;}
.contents .publishing .container .left_txt .content_img ul::after{content: '';display: block;clear: both;}
.contents .publishing .container .left_txt .content_img li{padding:0 10px;float: left;width: 25%;box-sizing: border-box;}
.contents .publishing .container .txt_title .left_align{padding-left: 100px;}
.contents .publishing .container .txt_title{color: #fff;}

.contents .solution .container .left_txt .content_img {margin: -55px 0 0 520px;}
.contents .solution .container .left_txt .content_img img{}
.contents .solution .container .left_txt .content_img ul{width: 660px;}
.contents .solution .container .left_txt .content_img ul::after{content: '';display: block;clear: both;}
.contents .solution .container .left_txt .content_img ul.left_content{margin-left: -118px;position: relative;top: -22px;}
.contents .solution .container .left_txt .content_img ul li{float: left;width: 70%;height: 202px;vertical-align: middle;display:table;padding: 0 30px;box-sizing: border-box;}
.contents .solution .container .left_txt .content_img ul li.img_box{width: 30%;text-align: center;padding: 0;}
.contents .solution .container .left_txt .content_img ul li p{display:table-cell;vertical-align:middle;margin: 0 auto;line-height: 25px;}
.contents .solution .container .left_txt .content_img ul li img{display:table-cell;vertical-align:middle;}
.contents .solution .container .left_txt .content_img ul li span{}
.contents .solution .container .left_txt>p{color: #444;}

.contents .managment .container .txt_title{color: #ececec;}

/* left_txt button */
.contents .content .container .left_txt>a{position: relative;margin-left: 30px;display: block;width: 187px;height: 48px;border: 1px solid #000;margin-top: 40px;line-height: 48px;color: #000;box-sizing: border-box;color: #000;z-index: 5;}
.contents .publishing .container .left_txt a{border-color: #fff;color: #fff;}
.contents .since .container .left_txt a{display: block;width: 187px;height: 48px;border: 1px solid #000;margin-top: 100px;line-height: 48px;color: #000;box-sizing: border-box;margin-left: 350px;}
.contents .content .container .left_txt>a span{display: inline-block;margin: 0 auto;width: 125px;height: 48px;padding-left: 10px;text-align: center;}
.contents .solution .container .left_txt a span{margin-right: 10px;}
.contents .managment .container .left_txt a span{margin-right: 10px;}
.contents .content .container .left_txt>a i{position: absolute;top: 9px;right: 2px;content: '';display: block;width: 48px;height: 31px;background: url(@img/right_arrow.png) 0 0 no-repeat;}
.contents .publishing .container .left_txt a i{background: url(@img/right_arrow2.png) 0 0 no-repeat;}
/* right_txt */
.contents .content .container .right_txt div{line-height: 22px;padding-left: 750px;box-sizing: border-box;}
.contents .content .container .right_txt div:last-child{margin-top: 85px;padding-left: 635px;}
/* content_map */
.contents .map .container img{width: 100%;}
.contents .map .map_box{}
.contents .map .container .left_txt{padding: 20px 0 0 0;box-sizing: border-box;padding-left: 35px;color: #2e2e2e;line-height: 25px;height: auto;}
.contents .content .container .left_txt .plus_txt{display: none;}
.contents .map .container .left_txt .map_txt{font-size: 16px;}
.contents .map .container{box-sizing: border-box;}
.contents .map .container .left_txt .map_txt span{display: inline-block;color: #ea8615;font-size: 16px;}
/* content background */
.contents .content{background-position: center top;background-repeat: no-repeat;;}
.contents .since{background-image: url(@img/home_bg_01.jpg);}
.contents .programming{background-color: #fff;}
.contents .managment{background-color: #f7f7f7;}
.contents .integration{background-color: #fff;}
.contents .publishing{background-image: url(@img/home_bg_05.jpg);}
.contents .solution{background-color: #fff;height: 770px;}
.contents .map{height: 470px;}
