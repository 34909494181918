
@media screen and (max-width: 640px){
/* 공통사항 */
.container{min-width: 100%;}
.contents>.container{min-width: 100%;overflow: hidden;}
#wrap{padding-bottom: 180px;}
.bookmark{height: 82px;}
p.web_phone{display: none;}
p.mobile_phone{display: block;}
p.mobile_phone>a{}

/* header */
.header{height: 82px;}
.header .logo{margin: 10px 0 10px 0;}
.header .logo a{width: 110px; }
.header .logo img{width: 110px;}
.header .logo{padding-left:30px;}
.header .container .nav_wrap{display: none;}
.header .menu_btn{display: block;margin-right:30px;background-size:30px;}
.header .mobile_nav_wrap{display: none;min-width: 100%;background-color: #fff;position: fixed;;right: 0;top: 82px;height: auto; }
.header .mobile_nav_wrap.on{display: block;box-shadow:0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%)}
.header .mobile_nav_wrap .mobile_nav_list li{border-top: 1px solid #eee;}
.header .mobile_nav_wrap .mobile_nav_list li.border_none{border: none;}
.header .mobile_nav_wrap .mobile_nav_list li.border_bottom{border-bottom: 1px solid #eee;}
.header .mobile_nav_wrap .mobile_nav_list li:hover a{background-color: #f6f6f6;}
.header .mobile_nav_wrap .mobile_nav_list li.on a{background-color: #f6f6f6;}
.header .mobile_nav_wrap .mobile_nav_list li a{position: relative;text-indent: 20px;display: block;font-weight: bold;font-size: 15px;color: #000;padding: 20px 0;}
.header .mobile_nav_wrap .mobile_nav_list li.on a .up_arrow{background: url(@img/up_arrow.png) 0 0 no-repeat;}
.header .mobile_nav_wrap .mobile_nav_list li a .up_arrow{position: absolute;top: 20px;right: 25px;display: block;width: 22px;height: 18px;background: url(@img/down_arrow.png) 0 0 no-repeat;}
.header .mobile_nav_wrap .mobile_nav_list li .mobile_nav_detail{display: none;}
.header .mobile_nav_wrap .mobile_nav_list li .mobile_nav_detail li{}
.header .mobile_nav_wrap .mobile_nav_list li .mobile_nav_detail li a{display: block;font-size: 14px;text-indent: 30px;padding: 15px 0;color: #000;}
.header .mobile_nav_wrap .mobile_nav_list li .mobile_nav_detail li a:hover{color: #f88e16;}
/* content */
.contents{padding-top: 82px;}
.contents .content{min-width: 100%;padding: 0 10px;box-sizing: border-box;height:450px}
.contents .map{}
.contents .solution{height:auto}
.contents .since{background-position: right center;background-size: auto auto}
.contents .since::before{display: none;}

.contents .content .container .left_txt{padding: 40px 0 80px 0;}
.contents .content .container .left_txt .plus_txt{padding-left: 20px;color: #444;}
.contents .content .container .left_txt p.content_title{font-size: 18px;line-height: 33px;padding: 0 20px;}
.contents .content .container .left_txt p.content_title em{font-size: 26px;}
.contents .content .container .left_txt p.content_title span{display: inline}
.contents .content .container .left_txt>a{margin-left: 20px;margin-top: 50px;}
.contents .content .container .txt_title{font-size: 40px;letter-spacing: normal;padding: 0 20px;width: 260px;}
.contents .content .container .txt_title .left_align{margin-bottom: -5px;}

.contents .since .container .left_txt a{margin-top: 50px;margin-left: 20px;}
.contents .programming .container .left_txt .content_img{margin: 20px auto 0 auto;width: 85%;}
.contents .programming .container .left_txt .content_img img{width: 100%}
.contents .publishing .container .left_txt .content_img {margin: 20px 0 0 0;}
.contents .publishing .container .left_txt .content_img ul{width: 95%;margin: 0 auto;}
.contents .publishing .container .left_txt .content_img ul li{padding: 0 5px;}
.contents .publishing .container .left_txt .content_img img{width: 100%;}
.contents .publishing .container .txt_title .left_align{padding-left: 35px;}
.contents .content.solution .container{height:auto}
.contents .solution .container .left_txt .content_img {margin: 30px auto 0 auto;width: 100%;}
.contents .solution .container .left_txt .content_img img{}
.contents .solution .container .left_txt .content_img ul{width: 90%;margin: 0 auto 30px auto;}
.contents .solution .container .left_txt .content_img ul::after{}
.contents .solution .container .left_txt .content_img ul.left_content{margin: 0 auto;top: 0;}
.contents .solution .container .left_txt .content_img ul li{padding-right: 0;}
.contents .solution .container .left_txt .content_img ul li.img_box{}
.contents .solution .container .left_txt .content_img ul li p{}
.contents .solution .container .left_txt .content_img ul li img{width: 100%;}
.contents .solution .container .left_txt .content_img ul li span{}
.contents .map .container{}
.contents .map .container .txt_title{bottom: 5px;}
.contents .map .container .left_txt{padding: 10px 0 50px 10px;}
.contents .map .container .left_txt .map_txt{font-size: 14px;}
.contents .map .container .left_txt .map_txt span{display: inline-block;}
/* footer */
.footer{min-width: 100%;}
.ft_wrap div{float: none;}
.ft_wrap .ft_logo{display: none;}
.ft_wrap .ft_content{padding: 10px 20px;margin: 0 auto;text-align: center;}
.ft_wrap .ft_content p{padding: 3px 0;text-align: center;}
p.web_phone{display: none;}
p.mobile_phone{display: block;}

/* aboutus */
/* path */
.contents .path{display: none;}
/* sub_title1 */
.container .sub_title{width: 100%; margin: 30px 0 0 0;padding: 0 10px;box-sizing: border-box;}
.container .sub_title h3{width: 100%;font-size: 26px;padding: 20px 0;}
.container .sub_title .intro{margin: 40px 0;}
.container .sub_title1 .intro div{font-size: 22px;}
.container .sub_title1 .intro div.intro_bg{width: auto;margin: 0  auto 20px;background-position: center 0;border: none;-webkit-background-size: 200px;
background-size: 400px;}
.container .sub_title .intro p{line-height: 24px;padding: 0 15px;}
.container .sub_title1 .intro_detail{}
.container .sub_title1 .intro_detail   div p{font-size: 26px;}
.container .sub_title1 .intro_detail .intro_contents li{float: none;width: 100%;margin-bottom: 20px;}
.container .sub_title1 .intro_detail .intro_contents li div{width: 100%;}
.container .sub_title1 .intro_detail .intro_contents li div p{font-size: 20px;padding-top: 0;}
.container .sub_title1 .intro_detail .intro_contents li div .number{width: 50px;}
.container .sub_title1 .intro_detail .intro_contents li div .number img{width: 100%;}
/* sub_title2 */
.intro_contents2 li{padding: 0;margin-bottom: 50px;}
.intro_contents2 li:nth-child(2n){background: none;}
.intro_contents2 li div{float: none;margin: 0 auto;}
.intro_contents2 .intro_contents_txt{padding: 0;max-width: 500px;}
.intro_contents2 .intro_contents_txt p{font-size: 22px;margin-top: 20px;text-align: center;}
/* sub_title3 */
.intro_contents3 li{font-size: 14px;}
.intro_contents3 li p{font-size: 16px;}

/* works */
.container .sub_title1 .sub_content .project_list{}
.container .sub_title1 .sub_content .project_list li{padding: 10px 10px 10px 14px;font-size: 13px;height: auto;line-height: 20px;}
.container .sub_title1 .sub_content .project_list li div{float: none;}
.container .sub_title1 .sub_content .project_list li span{}
.container .sub_title1 .sub_content .project_info{margin-top: 30px;}
.container .sub_title1 .sub_content .project_info li{font-size: 13px;margin-bottom: 8px;color: #747474;line-height: 20px;}
.container .sub_title2 .sub_content ul{}
.container .sub_title2 .sub_content ul li{float: none;width: 100%;}
.container .sub_title2 .sub_content ul li div{}
.container .sub_title2 .sub_content ul li img{}
.container .sub_title2 .sub_content ul li p{}
.container .sub_title2 .sub_content ul li p span{}
/* contactus */
.container .sub_title1 .sub_content>p{font-size: 13px;}
.container .sub_title1 .sub_content>p:first-child{font-size: 16px;}
.container .sub_title1 .sub_content form{}
.container .sub_title1 .sub_content table{width: 100%;}
.container .sub_title1 .sub_content table caption{}
.container .sub_title1 .sub_content table tbody{}
.container .sub_title1 .sub_content table tbody tr{}
.container .sub_title1 .sub_content table tbody tr.content_project th{}
.container .sub_title1 .sub_content table tbody th{font-size: 12px;}
.container .sub_title1 .sub_content table tbody td{padding: 9px;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(1) input{width: 100%;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(3) input{width: 40%;max-width: 100px}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(4) input{width: 40%;max-width: 100px}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(5) input{width: 22%;max-width: 65px;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(6) input{width: 22%;max-width: 65px;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(7) input{width: 40%;max-width: 100px}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(10) input{width: 100%;}
.container .sub_title1 .sub_content table tbody td span#phoneList-button{width: 35%;max-width: 65px;}
.container .sub_title1 .sub_content table tbody td span#mobileList-button{width: 35%;max-width: 65px;}
.container .sub_title1 .sub_content table tbody td span#mailList-button{width: 70%;max-width: 150px;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(7) span.emailbox{display: block;margin-bottom: 5px;}
.container .sub_title1 .sub_content table tbody th span{}
.container .sub_title1 .sub_content table tbody td input{}
.container .sub_title1 .sub_content table tbody tr.content_file label{}
.container .sub_title1 .sub_content table tbody tr.content_file label input{}
.container .sub_title1 .sub_content table tbody tr.content_file input{}
.container .sub_title1 .sub_content table tbody td textarea{margin: 0;}
.container .sub_title1 .sub_content .button_wrap{}
.container .sub_title1 .sub_content .button_wrap .buttons{}
.container .sub_title1 .sub_content .button_wrap .buttons button{}
.container .sub_title1 .sub_content .button_wrap .buttons button.check{}
/* Recruit */
.container .sub_title1 .sub_content ul li span.content_detail{display: block;padding: 10px 0 0 10px;line-height: 23px;}
.container .sub_title1 .sub_content .recruit>p{font-size: 16px;}
}
