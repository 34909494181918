/* aboutus */
/* sub_title1 */
.container .sub_title{margin: 0 18px;}
.container .sub_title h3{clear: both;font-size: 32px;font-weight: bold;padding: 50px 0;color: #444;}

.container .sub_title .intro{margin: 0 18px 100px 18px;}
.container .sub_title1 .intro div{position: relative;max-width: 320px;margin: 50px auto;font-size: 30px;color: #444;padding: 5px 20px;}
.container .sub_title1 .intro>div p{line-height: 40px;}
.container .sub_title1 .intro div.intro_bg{max-width: 472px;height: 174px;margin: 0  auto 50px;background: url(@img/sub_bg_01.jpg) 0 0 no-repeat;border: 1px solid #e6e6e6;padding: 0;}
.container .sub_title .intro p{ color: #444;line-height: 25px;}
.container .sub_title1 .intro div p:nth-of-type(2){text-align: right;}
.container .sub_title1 .intro div p span{position: absolute;background: url(@img/deco.png) 0 0 no-repeat;content: '';width: 20px;height: 20px;}
.container .sub_title1 .intro div p:nth-of-type(1) span{left: -5px;top: 0;}
.container .sub_title1 .intro div p:nth-of-type(2) span{right: -5px;bottom: 0;}
.container .sub_title1 .intro_detail{margin: 0 18px;}
.container .sub_title1 .intro_detail>div:nth-of-type(1){padding-bottom: 112px}
.container .sub_title1 .intro_detail>div:nth-of-type(2){border-top: 1px solid #e5e5e5;}
.container .sub_title1 .intro_detail>div p{position: relative;font-size: 32px;color: #444444;text-align: center;}
.container .sub_title1 .intro_detail>div p::after{position: absolute;left: 50%;top: 60px;content: '';display: block;width: 2px;height: 56px;background-color: #c4c4c4;}
.container .sub_title1 .intro_detail .intro_contents{overflow: hidden;padding-top: 44px;width: 100%;border-top: 1px solid #e5e5e5;}
.container .sub_title1 .intro_detail .intro_contents li{float: left;width: 33.333333%;padding: 10px;box-sizing: border-box;}
.container .sub_title1 .intro_detail .intro_contents li div{overflow: hidden;max-width: 310px;margin: 0 auto;color: #444;line-height: 23px;font-size: 14px;margin-bottom: 10px;}
.container .sub_title1 .intro_detail .intro_contents li div .number{display: inline-block;float: left;margin-right: 8px;}
.container .sub_title1 .intro_detail .intro_contents li div .color_orange{font-weight: bold;;}
.container .sub_title1 .intro_detail .intro_contents li div p{float: left;font-size: 22px;font-weight: bold;max-width: 230px;padding-top: 10px;line-height: 30px;}
.container .sub_title2{}
.container .sub_title3{padding-top: 70px;}
/* sub_title2 */
.intro_contents2{padding: 0 18px;}
.intro_contents2 li{overflow: hidden;padding: 30px;}
.intro_contents2 li:nth-child(2n){background-color: #fcfcfc;}
.intro_contents2 li div{float: left;}
.intro_contents2 .intro_contents_bg{width: 138px;height: 137px;}
.intro_contents2 .intro_contents_bg.sub_bg_02 {background: url(@img/sub_bg_02.png) 0 0 no-repeat;}
.intro_contents2 .intro_contents_bg.sub_bg_03 {background: url(@img/sub_bg_03.png) 0 0 no-repeat;}
.intro_contents2 .intro_contents_bg.sub_bg_04 {background: url(@img/sub_bg_04.png) 0 0 no-repeat;}
.intro_contents2 .intro_contents_bg.sub_bg_05 {background: url(@img/sub_bg_05.png) 0 0 no-repeat;}
.intro_contents2 .intro_contents_txt{padding: 5px 0 0 70px;line-height: 25px;color: #444;max-width: 70%;box-sizing: border-box;}
.intro_contents2 .intro_contents_txt p{font-size: 28px;font-weight: bold;margin-bottom: 25px;}
/* sub_title3 */
.intro_contents3 {padding: 0 18px;margin-bottom: 100px;}
.intro_contents3 li {padding: 40px 0;line-height: 25px;border-bottom: 1px solid #e5e5e5;color: #444444;}
.intro_contents3 li p {margin-bottom: 15px;font-size: 18px;font-weight: bold;color: #444444;}
/* 공통 */

/* contactus */
.container .sub_title1 .sub_content>p{color: #747474;font-size: 14px;padding: 20px 0;line-height: 23px;}
.container .sub_title1 .sub_content>p:first-child{color: #606060;font-size: 20px;padding: 0;font-weight: bold;}
.container .sub_title1 .sub_content form{max-width: 740px}
.container .sub_title1 .sub_content table{border-left: 1px solid #e5e5e5;border-right: 1px solid #e5e5e5;border-top: 1px solid #e5e5e5;width: 100%;}
.container .sub_title1 .sub_content table caption{position: absolute;visibility: hidden;font-size: 0;}
.container .sub_title1 .sub_content table tbody{}
.container .sub_title1 .sub_content table tbody tr{}
.container .sub_title1 .sub_content table tbody tr.content_project th{vertical-align: middle;}
.container .sub_title1 .sub_content table tbody th{min-width: 85px;text-align: center;border-right: 1px solid #e5e5e5;border-bottom: 1px solid #e5e5e5;padding: 14px 0;color: #555555;font-size: 14px;}
.container .sub_title1 .sub_content table tbody th.content_file_title{vertical-align: middle;}
.container .sub_title1 .sub_content table tbody td{position: relative;padding: 3px 15px;border-bottom: 1px solid #e5e5e5;width: auto;}
.container .sub_title1 .sub_content table tbody td span .ui-selectmenu-icon{margin-top: 2px;}
.container .sub_title1 .sub_content table tbody td span#phoneList-button{width: 80px;font-size: 12px;padding: 0 3px;height: 24px;line-height: 24px;box-sizing: border-box;}
.container .sub_title1 .sub_content table tbody td span#mobileList-button{width: 80px;font-size: 12px;padding: 0 3px;height: 24px;line-height: 24px;box-sizing: border-box;}
.container .sub_title1 .sub_content table tbody td span#mailList-button{width: 150px;font-size: 12px;padding: 0 3px;height: 24px;line-height: 24px;box-sizing: border-box;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(1) input{width: 250px;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(3) input{width: 250px;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(4) input{width: 150px;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(5) input{width: 80px;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(6) input{width: 80px;}
.container .sub_title1 .sub_content table tbody tr:nth-of-type(7) input{width: 150px;}

.container .sub_title1 .sub_content table tbody tr:nth-of-type(10) input{width: 250px;}
.container .sub_title1 .sub_content table tbody th span{color: #e62020;}
.container .sub_title1 .sub_content table tbody td input{width: 99%;border: 1px solid #e5e5e5;height: 24px;padding: 0;vertical-align: middle;box-sizing: border-box;}
.container .sub_title1 .sub_content table tbody tr.content_file label{position: relative;cursor: pointer;display: inline-block;vertical-align: middle;overflow: hidden;width: 70px;height: 25px;background-color: #fff; color: #555555; text-align: center;line-height: 25px;border: 1px solid #e5e5e5;border-radius: 5px;font-size: 11px;margin-top: 8px;}
.container .sub_title1 .sub_content table tbody tr.content_file label input{position: absolute;width: 0;height: 0;overflow: hidden;}
.container .sub_title1 .sub_content table tbody tr.content_file input{vertical-align: middle;display: inline-block;width: 100%;height: 28px;line-height: 28px;font-size: 11px;padding: 0;border: 0;}
.container .sub_title1 .sub_content table tbody td textarea{width: 99%;margin: 10px 0;border: 1px solid #e5e5e5;}
.container .sub_title1 .sub_content .button_wrap{max-width: 740px;margin: 30px 0;}
.container .sub_title1 .sub_content .button_wrap .buttons{width: 195px;margin: 0 auto;}
.container .sub_title1 .sub_content .button_wrap .buttons button{width: 95px;height: 32px;line-height: 26px;text-align: center;color: #fff;background-color: #585858;border: 0;font-family: 'Nanum Gothic', sans-serif;font-size: 14px;cursor: pointer;}
.container .sub_title1 .sub_content .button_wrap .buttons button.check{background-color: #f08206;}
/* works */
.container .sub_title .sub_content{margin: 0 18px;}
.container .sub_title1 .sub_content .project_list{}
.container .sub_title1 .sub_content .project_list li{overflow: hidden;height: 47px;border: 1px solid #efefef;line-height: 47px;margin-bottom: 12px;padding: 0 30px 0 34px;color: #747474;}
.container .sub_title1 .sub_content .project_list li div{float: right;overflow: hidden;}
.container .sub_title1 .sub_content .project_list li div span{float: left;text-align: center;display: inline-block;width: 62px;height: 25px;line-height: 18px;color: #de7600;border: 2px solid #f98f17;box-sizing: border-box;border-radius: 20px;margin: 10px 4px 0 0;}
.container .sub_title1 .sub_content .project_info{margin-top: 30px;}
.container .sub_title1 .sub_content .project_info li{position: relative;padding-left: 12px;font-size: 14px;margin-bottom: 8px;color: #747474;}
.container .sub_title1 .sub_content .project_info li::before{position: absolute;top: 7px;left: 3px;content: '';width: 2px;height: 2px;border-radius: 50%;background-color: #747474;}
.container .sub_title2 .sub_content ul{width: 100%;overflow: hidden;}
.container .sub_title2 .sub_content ul li{width: 33.333333%;float: left;padding: 10px;box-sizing: border-box;}
.container .sub_title2 .sub_content ul li div{max-width: 311px;margin: 0 auto;}
.container .sub_title2 .sub_content ul li img{width: 100%;display: block;}
.container .sub_title2 .sub_content ul li p{color: #747474;text-align: center;padding: 20px 0;line-height: 21px;}
.container .sub_title2 .sub_content ul li p span{color: #8e8e8e;}
/* recruit */
.container .sub_title1 .sub_content .recruit{margin-bottom: 60px;}
.container .sub_title1 .sub_content .recruit>p{font-size: 18px;color: #000;margin-bottom: 50px;font-weight: bold;line-height: 25px;}
.container .sub_title1 .sub_content .recruit ul{}
.container .sub_title1 .sub_content .recruit ul li{font-size: 13px;margin: 15px 0;}
.container .sub_title1 .sub_content .recruit ul li span.content_title{display: inline-block;vertical-align: middle;width: 54px;height: 21px;line-height: 19px;text-align: center;color: #fff;background-color: #f88e16;border-radius: 4px;font-size: 12px;margin-right: 10px;}
.container .sub_title1 .sub_content .recruit ul li span.content_detail{font-size: 13px;color:#000}
.container .sub_title1 .sub_content .recruit ul li span.content_detail a{color:#000}
