html, body, div, span, applet, object, iframe, table, caption, tbody, tfoot, thead, tr, th, td, 
del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, 
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, 
dl, dt, dd, ol, ul, li, fieldset, form, label, legend,figure, figcaption {
 vertical-align: baseline;
 font-family: inherit;
 font-weight: inherit;
 font-style: inherit;
 font-size: 100%;
  padding: 0;
 margin: 0;
 border: 0;
 }
/* remember to define focus styles!
:focus {
 outline: 0;
 } */
body {
 background: white;
 line-height: 1;       /*- 한글 기준은 1.2 -*/
 color: black;
 }
ol, ul {
 list-style: none;
 }
/* tables still need cellspacing="0" in the markup */
table {
 border-collapse: separate;
 border-spacing: 0;
 }
caption, th, td {
 font-weight: normal;
 text-align: left;
 }
/* remove possible quote marks (") from <q> & <blockquote> */
blockquote:before, blockquote:after, q:before, q:after {
 content: "";
 }
blockquote, q {
 quotes: "" "";
 }
 a{text-decoration:none;}
 .hidden{position:absolute; left:-9999px; top:-9999px; width:0; height:0; font-size:0; line-height:0;}












