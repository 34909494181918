@import url(https://fonts.googleapis.com/earlyaccess/nanumgothic.css);
html,body { height: 100%; margin: 0; padding: 0; }
body{font-family: 'Nanum Gothic', sans-serif;}
#wrap{width: 100%;min-height:100%;position: relative;padding-bottom: 108px;box-sizing: border-box;}
.header{width: 100%;position: fixed;top: 0;height: 115px;box-sizing: border-box;background-color: #fff;z-index: 10;box-shadow:0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%)}
/* 공통사항 */
#root{height: 100%;}
.main{display: flex;flex-direction: column;height: 100%;}
.container{max-width: 1250px;min-width: 840px;margin: 0 auto;position: relative;height: 100%;}
.contents{flex:1;padding-top: 115px;}
.bookmark{display: block;width: 1px;height: 115px;}
.color_orange{color:#e67a00}
.ui-selectmenu-menu{font-size: 12px;}

/* header */
.header .container{display:flex;justify-content: space-between;align-items: center;}
.header .logo{}
.header .logo a{display: block;width: 152px;}
.header .logo a img{width:100%;display:block;}
.header .menu_btn{width: 40px;height: 34px;display: none;background: url(@img/menu3.png) 0 3px no-repeat;}
.header .menu_btn.on{background: url(@img/menu2.png) 0 0 no-repeat;}
.header .mobile_nav_wrap{display: none;}

.header .container .nav_wrap{text-align: center;width: 650px;height: 100%;display: flex;}
.header .nav_wrap>.nav_list{width: 100%;height: 59px;display: flex;height: 100%;}
.header .nav_wrap>.nav_list>li{width: 20%;overflow: hidden;height: 100%;align-items: center;}
.header .nav_wrap>.nav_list>li>a{position: relative;display: block;font-weight: bold;font-size: 19px;color: #000;height: 100%;line-height: 115px;border-bottom: 3px solid transparent;box-sizing: border-box; }
.header .nav_wrap>.nav_list>li:hover>a{border-bottom: 3px solid #f88e16; }
/* .header .nav_wrap>.nav_list>li>a:focus{border-bottom: 3px solid #f88e16;} */
.header .nav_wrap>.nav_list>li:hover>a + .nav_detail{display: block;}
.header .nav_wrap>.nav_list>li>a.on{color:#f88e16}
.header .nav_wrap>.nav_list>li>.nav_detail{text-align: left;position: absolute;display: none;width: 130px;background-color: #f9f9f9;}
.header .nav_wrap>.nav_list>li>.nav_detail .deco{width: 100%;height: 3px;display: block;}
/*.header .nav_wrap>.nav_list>li>.nav_detail::before{content: '';display: block;height: 90px;position: absolute;top: 0;background-color: #fff;width: 100%;}
.header .nav_wrap>.nav_list>li:first-child>.nav_detail::before{height: 130px;}*/
.header .nav_wrap>.nav_list>li>.nav_detail.on{display: block;}
.header .nav_wrap>.nav_list>li>.nav_detail>li{position: relative;z-index: 10;}
.header .nav_wrap>.nav_list>li>.nav_detail>li>a{display: block;font-size: 14px;font-weight: bold;text-indent: 15px;color: #000;padding: 15px 0;}
.header .nav_wrap>.nav_list>li>.nav_detail>li>a:hover{color: #f88e16;}
/* footer */
p.web_phone{display: block;}
p.mobile_phone{display: none;}
p.mobile_phone a{color:#2e2e2e}
/*footer*/
.footer{background-color: #5a5a5a;width: 100%;min-width: 1250px;}
.ft_wrap{padding: 17px 0;overflow: hidden;display: flex;box-sizing: border-box;}
.ft_wrap>div{}
.ft_wrap>.ft_logo{width: 10%;display: flex;align-items: center;}
.ft_wrap>.ft_logo img{width:100%}
.ft_wrap>.ft_content{padding: 10px 40px;line-height: 18px;box-sizing: border-box;width: 90%;}
.ft_wrap p{color: #fff;font-size: 13px;text-align: left;opacity: .5;}
.ft_wrap p a{color:#fff}
/* contents path */
.contents>.container{min-width: 1250px;}
.contents>.path{width: 100%;min-width: 1250px;border-top: 1px solid #e6e6e6;border-bottom: 1px solid #e6e6e6;box-sizing: border-box;height: 48px;}
.contents>.path>ul{max-width: 1250px;min-width: 1250px;margin: 0 auto;}
.contents>.path>ul::after{content: '';clear: both;display: block;}
.contents>.path>ul>li{position: relative;float:left;border-right: 1px solid #e6e6e6;width: 176px;text-align: center;box-sizing: border-box;padding: 9px 0;line-height: 29px;color: #717171;font-size: 14px;}
.contents>.path>ul>li:nth-of-type(1){width: 58px;}
.contents>.path>ul>li:nth-of-type(1)>a{width: 30px;}
.contents>.path>ul>li:nth-of-type(1)>a>img{vertical-align: middle;}
.contents>.path>ul>li>a{position: relative;display: block;margin: 0 auto;color: #717171;}
.contents>.path>ul>li.sub_menu{text-align: left;padding-left: 20px;}
.contents>.path>ul>li.sub_menu>a{font-weight: bold;color: #f98f17;}
.contents>.path>ul>li.sub_menu>a>.up_arrow{position: absolute;top: 7px;right: 17px;width: 22px;height: 18px;content: '';display: block;background: url(@img/down_arrow.png) 0 0 no-repeat;}
.contents>.path>ul>li.sub_menu>a>.up_arrow.on{background: url(@img/up_arrow.png) 0 0 no-repeat;}
.contents>.path>ul>li.sub_menu>.sub_menu_detail{display:none;position: absolute;top: 47px;right: -1px;width: 177px;background-color: #fff;z-index: 1;border-left: 1px solid #e6e6e6;border-right: 1px solid #e6e6e6;box-sizing: border-box;}
.contents>.path>ul>li.sub_menu>.sub_menu_detail.on{display: block;}
.contents>.path>ul>li.sub_menu>.sub_menu_detail>li{padding-left: 20px;border-bottom: 1px solid #e6e6e6;height: 48px;line-height: 48px;}
.contents>.path>ul>li.sub_menu>.sub_menu_detail>li>a{display: block;color: #717171;}
.contents>.path>ul>li.sub_menu>.sub_menu_detail>li>a:hover{color: #f98f17;}
